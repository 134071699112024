import HttpManager from '../../services/HttpManager';
import { API } from '../../common/api';
import { FETCH_ENV_CONFIG } from '../actions/action-types';
import { put, takeEvery } from 'redux-saga/effects';
import { setEnvConfig } from '../actions/app.actions';
import { getNgService } from '../../common/utils';

function* fetchEnvConfig() {
  try {
    const config = yield HttpManager.get(API.ENV_CONFIG.FETCH);
    yield put(setEnvConfig(config));
    // Initialize environment settings data for angular
    getNgService(($rootScope) => {
      setEnvironmentSettings($rootScope, config);
    });
  } catch(e) {
    console.error('an error occurred while trying to fetch environment config');
  }
}

// That's in order to be independent on static js data - env*.js
function setEnvironmentSettings($rootScope, { resourcesUrl, helpCenterDomain, globalEnvironment, newCommandCenterModulesExposure }) {
  // prefix = resources url prefix. when extracting command center to s3 - will be '/'
  if (resourcesUrl) {
    $rootScope.envConfig.prefix = resourcesUrl;
    $rootScope.app.prefix = resourcesUrl;
  } else {
    $rootScope.envConfig.prefix = $rootScope.envConfig.prefix ? $rootScope.envConfig.prefix : '/';
    $rootScope.app.prefix = $rootScope.envConfig.prefix;
  }
  // previewCdnUrl = preview served via s3
  $rootScope.app.previewCdnUrl = $rootScope.app.previewCdnUrl ? $rootScope.app.previewCdnUrl : $rootScope.app.prefix + 'form-preview/index.html';
  // region = is used in dev + demo envs. to be considered for deprecation
  $rootScope.app.region = globalEnvironment ? globalEnvironment : $rootScope.app.region;
  $rootScope.envConfig.helpCenterDomain = helpCenterDomain ? helpCenterDomain : $rootScope.envConfig.helpCenterDomain;
  $rootScope.app.newCommandCenterModulesExposure = newCommandCenterModulesExposure;
}

export default function* () {
  yield takeEvery(FETCH_ENV_CONFIG, fetchEnvConfig);
}
