//#region imports
import React from 'react';
import styled from 'styled-components';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import FieldMappingView from './FieldMappingView/FieldMappingView';
import EcOptionsChanged from './EcOptionsChanged/EcOptionsChanged';
import NewQuestionTypeForm from './NewQuestionTypeForm/NewQuestionTypeForm';
import MakeFieldReusableForm from './MakeFieldReusableForm/MakeFieldReusableForm';
//#endregion imports

const StyledMarginedDiv = styled.div`
  margin: -15;
`;

/**
 * About the business case:
 * - Wraps three different scenarios.
 * - 1.- EC fields that are modified from MEC have to display a msg to the user about this.
 * - 2.- The making of a `Reusable Field` (connected with `MEC`).
 * - 3.- TBD 
 */
function ReportFieldMappingModal(props) {
  const {
    fieldState, additionalInfo,
    handleSetFieldState, handleSetFieldObj,
    fieldNameRequire, setIsApplyValid, baseTextPath,
    explicitFieldDetach,
  } = props;
  const { 
    ecFields, ecChangedFields, isReadOnly, 
    isNewFormField, ecOptionsChanged, isAnEcBrokenField, 
    isDetachableProvisionEnabled,
  } = additionalInfo || {};

  function getModalContent() {
    const componentProps = {
      fieldState,
      handleSetFieldState,
      handleSetFieldObj,
      fieldNameRequire,
      setIsApplyValid,
      baseTextPath,
      explicitFieldDetach,
    };

    if (ecOptionsChanged) {
      return <EcOptionsChanged ecFields={ecChangedFields} />;
    } else if (isReadOnly) {
      return <FieldMappingView fieldState={fieldState} baseTextPath={baseTextPath} explicitFieldDetach={explicitFieldDetach} />;
    } else if (isNewFormField) {
      return <NewQuestionTypeForm ecFields={ecFields} isAnEcBrokenField={isAnEcBrokenField} {...componentProps} />;
    } else if (isAnEcBrokenField && !isDetachableProvisionEnabled) {
      return <EcOptionsChanged />;
    }

    return <MakeFieldReusableForm {...componentProps} />;
  }

  return (
    <StyledMarginedDiv>
      {getModalContent()}
    </StyledMarginedDiv>
  );
}

const basicModalProps = {
  title: 'Report Field Mapping',
  okButtonText: 'Apply',
  cancelButtonText: 'Cancel',
  modalClassName: 'reuseable-field-modal',
};

export default withModal(basicModalProps)(ReportFieldMappingModal);