import React from 'react';
import { StyledCustomInterceptContainer , StyledCustomInterceptHeader ,StyledTextSettingsDescription  } from './StyledCustomIntercept';
import AlertInputComponent from '../Alert/AlertInputComponent';
import { getSingleText } from '../../../../common/utils';
import { FLAVORS } from '../../MobileEngagementDesign';
import Input from '@mdigital/components/dist/components/Input';
import Textarea from '@mdigital/components/dist/components/Textarea';

const CustomIntercept = ({ flavor }) => (
  <StyledCustomInterceptContainer>

    <StyledCustomInterceptHeader>Text Settings</StyledCustomInterceptHeader>
    <StyledTextSettingsDescription>
      {getSingleText('app.pages.global.mobile.engagementDesign.invitation.CUSTOM.textSettingsDescription')}
    </StyledTextSettingsDescription>

    <AlertInputComponent 
      name="trigger.triggerCustomInterceptContract.customInvitationTitle" 
      title="Title"
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange} digPlaceholder="Insert title here"/>
      )} />

    <AlertInputComponent 
      name="trigger.triggerCustomInterceptContract.customInvitationDescription" 
      title="Description"
      render={({ input }) => (
        <Textarea placeholder="Insert description here" {...input}/>
      )} />

    <StyledCustomInterceptHeader>Button Settings</StyledCustomInterceptHeader>
    <AlertInputComponent 
      name="trigger.triggerCustomInterceptContract.customProvideButtonText" 
      title={`${flavor === FLAVORS.APP_RATING ? 'Rate App Text' : 'Provide Feedback Text'}`}
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange} digPlaceholder={`${flavor === FLAVORS.APP_RATING ? 'Rate App' : 'Provide Feedback'}`}/>
      )} />

    <AlertInputComponent 
      name="trigger.triggerCustomInterceptContract.customLaterButtonText" 
      title="Defer Text"
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange} digPlaceholder="Maybe Later"/>
      )} />

    <AlertInputComponent 
      name="trigger.triggerCustomInterceptContract.customDeclineButtonText" 
      title="Decline Text"
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange} digPlaceholder="No thanks"/>
      )} />

  </StyledCustomInterceptContainer>
);

export default CustomIntercept;
