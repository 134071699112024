export const NAME_MAX_CHARACTERS = '52';
export const MAX_LIMIT_BYTES = 2000000;
export const DEFAULT_FOLDER_NAME = 'Home';

export const PROMPT_TYPES = {
  ALERT: 'ALERT',
  BANNER: 'BANNER'
}

export const CARDS_ACTION_TYPES = {
  PUBLISH: 'publish',
  CREATE: 'create',
  EDIT: 'edit',
  LOCK: 'lock',
  DELETE: 'delete',
  TRANSLATE: 'translate',
  DARK_MODE: 'darkMode',
}

export const PROPERTY_TYPES = {
  ANYWHERE: 'anywhere',
  ANDROID: 'mobileAndroid',
  IOS: 'mobileIOS',
  WEBSITE: 'website',
}

export const POLLING_INTERVAL = 5000;
export const UNKNOWN_ERROR = 'Unknown error occurred';
export const DATE_FORMAT = 'MMMM DD, YYYY';
export const SIDE_MENU_WIDTH = 72;
export const FREEZEMODE_ERROR = "We currently can't handle your request as the command center is in \"Freeze mode\""

export const ROLE_TYPES = {
  ROLE_ADMIN:'ROLE_ADMIN',
	SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
};

export const LOGOUT_BUTTON = 'logoutBtn'

export const SSO_HELP_LINK = 'https://docs.medallia.com/en/medallia-digital'

export const BANNER_TYPES = {
  WARNING: 'WARNING',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
}

export const NAVBAR_AND_BTNS_ROWS_HEIGHT = 116;
