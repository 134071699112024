import React from 'react';
import DigTitle from '@mdigital/components/dist/components/Title';

const fieldNameStyle = {
  textAlign: 'left',
  font: 'normal normal normal 14px/16px Open Sans',
  letterSpacing: 0,
  color: '#40440A',
};

const subTitleStyle = {
  textAlign: 'left',
  font: 'normal normal normal 14px/16px Open Sans',
  letterSpacing: 0,
  color: '#595959',
  opacity: 0.64,
};

export default function ReuseableFieldName({ title, subTitle, customStyle = {} }) {
  return <div>
    <div style={{ marginBottom: 5 }}>
      <DigTitle digLabel={title} additionalDigLabelStyle={{ ...fieldNameStyle, ...customStyle.fieldNameTitle }} />
    </div>
    <DigTitle digLabel={subTitle} additionalDigLabelStyle={{ ...subTitleStyle, ...customStyle.fieldNameSubtitle }} />
  </div>;
}
