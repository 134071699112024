import React, { useContext } from 'react';
import { PanelSubHeader } from '../../../../StyledTriggeringModal';
import { StyledContentContainer } from '../../StyledTriggerByTargeting';
import TargetingField from '../../../Shared/TargetingField';
import { Input } from '@m/alchemy-ui';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TargetingActions } from '../../Actions';
import { TriggerContext } from '../../../..';
import { TextPathPrefix } from '../..';
import { getSingleText } from '../../../../../../../common/utils';
import { AlertBasicFields } from './AlertBasicFields';

const Alert = () => {
  const { triggerState , triggerDispatch, isReadOnly } = useContext(TriggerContext);
  
  return (
    <StyledContentContainer>
      <AlertBasicFields />
      <PanelSubHeader>{getSingleText(`${TextPathPrefix}.buttonsHeader`)}</PanelSubHeader>
      <TargetingField
        label="Provide Feedback"
        required
        errorMessage="Rate App Option is Required"
        className="custom-field-margin"
        disabled={isReadOnly}
        render={<Input 
          value={getNestedProperty(triggerState, 'trigger.provideButtonText')} 
          onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_PROVIDE_BUTTON_TEXT , payload : e.target.value })} />}
      />
      <TargetingField
        label="Maybe Later"
        required
        errorMessage="Maybe Later Option is Required"
        disabled={isReadOnly}
        render={<Input 
          value={getNestedProperty(triggerState, 'trigger.laterButtonText')}
          onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_LATER_BUTTON_TEXT , payload : e.target.value })} />}
      />
      <TargetingField
        label="Decline"
        required
        errorMessage="Decline Option is Required"
        disabled={isReadOnly}
        render={<Input
          value={getNestedProperty(triggerState, 'trigger.declineButtonText')} 
          onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_DECLINE_BUTTON_TEXT , payload : e.target.value })} />}
      />
    </StyledContentContainer>);
};
export default Alert;
