import React from 'react';
import { getSingleText } from '../../../../common/utils';
import ReusableFieldForm from '../components/ReusableFieldForm/ReusableFieldForm';
import ReuseableFieldName from '../components/ReuseableFieldName/ReuseableFieldName';
import { ReuseableFieldNameWrapper } from './MakeFieldReusableFormStyled';

export default function MakeFieldReusableForm({
  customStyle = {},
  baseTextPath,
  fieldState,
  handleSetFieldState,
  handleSetFieldObj,
  fieldNameRequire,
  setIsApplyValid,
  explicitFieldDetach,
}) {

  return (
    <div>
      <ReuseableFieldNameWrapper>
        <ReuseableFieldName 
          subTitle={ fieldState.ecKey } 
          customStyle={ customStyle }
          title={ getSingleText(`${baseTextPath}reportingFieldName`) }
        />
      </ReuseableFieldNameWrapper>
      <ReusableFieldForm
        customStyle={customStyle}
        baseTextPath={baseTextPath}
        fieldState={fieldState}
        handleSetFieldState={handleSetFieldState}
        handleSetFieldObj={handleSetFieldObj}
        fieldNameRequire={fieldNameRequire}
        setIsApplyValid={setIsApplyValid}
        explicitFieldDetach={explicitFieldDetach}
      />
    </div>
  );

}
