import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { ReuseableFieldNameWrapper } from './FieldMappingViewStyled';
import { DetachFieldLink } from '../components/DetachFieldLink/DetachFieldLink';
import ReuseableFieldName from '../components/ReuseableFieldName/ReuseableFieldName';
import { checkProvision } from '../../../../redux/store/store-helper';

export default function FieldMappingView({ fieldState, baseTextPath, customStyle = {}, explicitFieldDetach }) {
  const BROKEN_FIELD_TEXT = getSingleText(`${baseTextPath}brokenFieldText`);

  const ecKeySubTitle = fieldState && fieldState.ecKey ? fieldState.ecKey : BROKEN_FIELD_TEXT;

  const ecFriendlyNameSubTitle = fieldState && fieldState.ecFriendlyName ? fieldState.ecFriendlyName : BROKEN_FIELD_TEXT;

  const isDetachableProvisionEnabled = checkProvision(DigProvisions.DETACH_FROM_EC_FIELD);

  const isDetachableField = isDetachableProvisionEnabled && explicitFieldDetach && fieldState.ecKey;

  return (
    <div>
      <ReuseableFieldNameWrapper>
        <ReuseableFieldName title={getSingleText(`${baseTextPath}reportingFieldName`)} subTitle={ ecKeySubTitle } customStyle={customStyle} />
      </ReuseableFieldNameWrapper>
      <div style={{ marginBottom: 14 }}>
        <ReuseableFieldName title={getSingleText(`${baseTextPath}reusableFieldName`)} subTitle={ ecFriendlyNameSubTitle } customStyle={customStyle} />
      </div>
      {isDetachableField && <DetachFieldLink
        explicitFieldDetach={explicitFieldDetach}
        baseTextPath={baseTextPath}
      />}
    </div>
  );
}
