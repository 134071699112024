import { combineReducers } from 'redux';
import app from './app.reducer';
import auth from './auth.reducer';
import customParameters,  { allEngagements, customParametersActions } from './custom-parameters.reducer';
import folders from './folders.reducer';
import forms from './forms.reducer';
import localization from './localization.reducer';
import reusableFields from './reusable-fields.reducer';
import property from './property.reducer';
import profile from './profile.reducer';
import appRatings from './appRatings.reducer';
import appRatingRules from './appRatingRules.reducer';
import mobilePreview from './mobilePreview.reducer';
import packages from './packages.reducer';
import resources from './resources.reducer';
import ruleEngine from './rule-engine.reducer';
import theme from './theme.reducer';

const rootReducer = combineReducers({
  app,
  auth,
  mobilePreview,
  customParameters,
  allEngagements,
  customParametersActions,
  folders,
  forms,
  appRatings,
  appRatingRules,
  localization,
  reusableFields,
  property,
  profile,
  packages,
  resources,
  ruleEngine,
  theme,
});

export default rootReducer;