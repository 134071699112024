import React, { useMemo, useState } from 'react';
import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getSingleText } from '../../../common/utils';
import { Radio, RadioGroup } from '@m/alchemy-ui';
import PropTypes from 'prop-types';
import { StyledFormVersionSelectDialogBody } from './StyledFormVersionSelectDialogBody';


const FormVersionSelectDialog = (props) => {
  const texts = 'app.pages.forms.selection';
  const forms = useMemo(() => [
    { id: props.publishedId, label: getSingleText(`${texts}.published`) },
    { id: props.draftId, label: getSingleText(`${texts}.draft`) }
  ], [props.draftId, props.publishedId]);
  const [selection, setSelection] = useState(null);
  const description = getSingleText(`${props.textsByType}.description`);

  return props.render({
    size: 'small',
    title: getSingleText(`${props.textsByType}.title`),
    CustomBody: StyledFormVersionSelectDialogBody,
    body: (
      <React.Fragment>
        {
          description && <p dangerouslySetInnerHTML={
            { __html: description }
          }></p>
        }
        <p>{getSingleText(`${props.textsByType}.question`)}</p>
        <RadioGroup layout="column" selection={selection} onChange={(selection) => {
          setSelection(selection);
        }}>
          {({ isSelected, handleChange }) =>
            forms.map((form) => (
              <Radio
                key={form.id}
                value={form.id}
                label={form.label}
                name="column-group"
                checked={isSelected(form)}
                onChange={() => handleChange(form)}
                className="radio-selection"
              />
            ))
          }
        </RadioGroup>
        <br/>
      </React.Fragment>
    ),
    close: {
      title: getSingleText(`${texts}.close`),
      priority: 'tertiary',
    },
    submit: {
      title: getSingleText(`${props.textsByType}.confirm`),
      isValid: selection,
      callback: () => props.confirmCallback(selection),
    },
  });
};

export const openFormVersionSelectDialog = ({ confirmCallback, draftId, publishedId, textsByType }) => {
  ModalsService.showCustomModal(FormVersionSelectDialog, {
    confirmCallback,
    draftId,
    publishedId,
    textsByType,
  });
};

FormVersionSelectDialog.propTypes = {
  confirmCallback: PropTypes.func,
  draftId: PropTypes.number,
  publishedId: PropTypes.number,
  textsByType: PropTypes.string,
};