import React, { useState,useEffect, useMemo } from 'react';
import { ReusableFieldFormWrapper,InputTitleWrapper,ReuseTitleWrapper, RequireText } from './ReusableFieldFormStyled';
import RadioButton from '../ReusableFieldRadioButton/RadioButton';
import DigCheckbox from '@mdigital/components/dist/components/Checkbox';
import Input from '@mdigital/components/dist/components/Input';
import DigMessage from '@mdigital/components/dist/components/Message';
import DigTitle from '@mdigital/components/dist/components/Title';
import { getSingleText } from '../../../../../common/utils';
import { CheckboxWrapper } from '../../../EmbeddedTargeting/Targeting/Metadata/EmbeddedTargetingMetadataStyled';
import { getProfile } from '../../../../../redux/store/store-helper';
import { ROLE_TYPES } from '../../../../../common/constants';
import { DetachFieldLink } from '../DetachFieldLink/DetachFieldLink';

const subTitleStyle = {
  fontSize: 12,
  color: '#5B5B60',
  letterSpacing: 0,
  font: 'normal normal normal 14px/16px Open Sans',
  marginTop: 30,
};

const additionInputStyle = {
  borderRadius: 0,
  width: '100%',
  height: 34,
  marginRight: 8,
  paddingLeft: 8,

};

const requireMsgStyle = {
  textAlign: 'left',
  font: 'normal normal normal 14px/16px Open Sans',
  letterSpacing: -0.35,
  color: '#A94442',
};

const SINGLE = 'single';
const ALL = 'all';

export default function ReusableFieldForm(
  { customStyle = {}, baseTextPath, handleSetFieldState, fieldState, handleSetFieldObj, fieldNameRequire, setIsApplyValid, explicitFieldDetach }
) {
  const [selectedRadioButton, setSelectedRadioButton] = useState(SINGLE);
  const [isReusableField, setIsReusableField] = useState(fieldState.isMarkedReusable); 
  const { userRole } = getProfile();
  const isAdmin = userRole ===  ROLE_TYPES.ROLE_ADMIN;

  const radioButtons = [
    {
      containerStyle:{ alignItems: 'center',marginBottom: -4,marginTop: -7 },
      title: getSingleText(`${baseTextPath}propertyOnly`),
      onChange: ()=> setSelectedRadioButton(SINGLE),
      digRadioButtons: [{ digLabel: '', digValue: SINGLE }],
    },
    {
      containerStyle:{ alignItems: 'center',marginTop: -14 },
      title: getSingleText(`${baseTextPath}allProperties`),
      onChange: ()=> setSelectedRadioButton(ALL),
      digRadioButtons: [{ digLabel: '', digValue: ALL }],
    }
  ];

  useEffect(()=>{
    initialForm();
  },[]);

  useEffect(()=>{
    if(selectedRadioButton !== null) {
      handleSelectedRadioButtonsChange();
    }
  },[selectedRadioButton]);

  useEffect(() => {
    const newStateField = {
      ...fieldState,
      isMarkedReusable: isReusableField,
      isExistingField: false,
      ecFriendlyName: isReusableField ? fieldState.ecFriendlyName : '',
      isAccountWide: isReusableField ? fieldState.isAccountWide : false,
    };
    handleSetFieldObj(newStateField);
      
  }, [isReusableField]);

  function initialForm() {
    if(fieldState.isAccountWide !== undefined) {
      setSelectedRadioButton(fieldState.isAccountWide ?  ALL : SINGLE);
    }
    if (setIsApplyValid) {
      setIsApplyValid(false);
    }
  }

  function handleSelectedRadioButtonsChange() {
    const newStateField = {
      ...fieldState,
      isAccountWide: selectedRadioButton === ALL,
    };
    handleSetFieldObj(newStateField);
  }

  const memoRadioButtons = useMemo(function renderRadioButtons() {
    return radioButtons.map((item,index) => <RadioButton 
      labelStyle={{ marginLeft: -13,marginBottom: 7 }}
      key={index}
      selectedRadio={selectedRadioButton}
      {...item}
    />);
  },[selectedRadioButton]);

  return isAdmin ? <div>
    <ReusableFieldFormWrapper className='make-reuse-wrapper' style={{ paddingLeft: 30 }}>
      <CheckboxWrapper className='make-reuse-checkbox'>
        <DigCheckbox
          digIsChecked={isReusableField}
          label={getSingleText(`${baseTextPath}makeThisFieldReusable`)}
          handleCheckboxChange={() => setIsReusableField(!isReusableField)}
        />
      </CheckboxWrapper>
      <DetachFieldLink
        explicitFieldDetach={explicitFieldDetach}
        baseTextPath={baseTextPath}
        ecKey={fieldState.ecKey}
      />
      {
        isReusableField && <React.Fragment>
          <InputTitleWrapper className='field-name-wrapper'>
            <RequireText>*</RequireText> 
            <DigTitle digLabel={getSingleText(`${baseTextPath}reusableFieldName`)} digTooltip={getSingleText(`${baseTextPath}reusableFieldNameTooltip`)} />
          </InputTitleWrapper>
          <Input
            digRequired
            digValue={fieldState.ecFriendlyName}
            digOnChange={(value) => handleSetFieldState('ecFriendlyName',value)}
            parentCustomStyle={{ height: 34 }}
            inputStyle={{ ...additionInputStyle, marginBottom: 12 }}
            autoFocus={false}
            enableCounter
            digPlaceholder={getSingleText(`${baseTextPath}ecFriendlyNamePlaceholder`)}
            maxLength={150}
          />
          {fieldNameRequire && <DigTitle digLabel={getSingleText(`${baseTextPath}ecFriendlyNameRequireMsg`)} additionalDigLabelStyle={requireMsgStyle} />}
          <ReuseTitleWrapper style={{ marginTop: fieldNameRequire ? 9 : 24, ...(customStyle.reuseUsageContainer || {}) }}>
            <DigTitle digLabel={getSingleText(`${baseTextPath}reuseUsage`)} additionalDigLabelStyle={{ ...subTitleStyle }} />
          </ReuseTitleWrapper>
          <div style={customStyle.reuseUsageContainer}>
            {memoRadioButtons}
          </div>
        </React.Fragment>
      }
    </ReusableFieldFormWrapper>
    {isReusableField && 
      <div style={customStyle.noteContainer}>
        <DigMessage 
          digMessageBody={getSingleText(`${baseTextPath}reuseableFieldNoteMsg`)}
          digId={'id'} 
          digType='warning'
        />
      </div>
    }
  </div> : null;
}