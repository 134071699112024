import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReportFieldMappingModal from './ReportFieldMappingModal';
import { ModalContainer } from './ReportFieldMappingModalContainerStyled';
import { REUSEABLE_FIELD_MODAL } from '../../../common/enums';
import { getSingleText } from '../../../common/utils';
import { ROLE_TYPES } from '../../../common/constants';
import { getProfile } from '../../../redux/store/store-helper';

function ReportFieldMappingModalContainer(props) {
  const [fieldState, setFieldState] = useState({});
  const [isApplyValid, setIsApplyValid] = useState(true);
  const [fieldNameRequire, setFieldNameRequire] = useState(false);

  const { userRole } = getProfile();
  const isAdmin = userRole ===  ROLE_TYPES.ROLE_ADMIN;

  const BASE_TEXT_PATH = 'app.pages.forms.builder.tabs.fieldSettings.fieldMappingModal.';

  useEffect(() => {
    handleStateValidation();
  },[fieldState]);

  useEffect(()=>{
    if(props.additionalInfo) {
      initialFieldState();
    }
  },[]);

  function initialFieldState() {
    const { additionalInfo } = props;
    const fieldState = { ...additionalInfo.fieldState, uniqueName: additionalInfo.uniqueName };

    if(!additionalInfo.fieldState && additionalInfo.savedFieldData) {
      fieldState.ecKey = additionalInfo.savedFieldData.ecKey  || '';
      fieldState.ecFriendlyName = additionalInfo.savedFieldData.ecFriendlyName || '';
    }
    setFieldState(fieldState);
  }

  function handleSetFieldState(key,value) {
    const newFieldState = {
      ...fieldState,
      [key]: value,
    };
    setFieldState(newFieldState);
  }

  function handleSetFieldObj(newObj) {
    setFieldState(newObj);
  }

  function handleStateValidation() {
    if(props.additionalInfo.isReadOnly) {
      setIsApplyValid(false);
      return;
    }
    if(fieldState.ecFriendlyName && fieldState.isExistingField ){
      setIsApplyValid(false);
    }
  }

  function fieldNameValidation() {
    if(fieldState.isMarkedReusable && (!fieldState.ecFriendlyName || !fieldState.ecFriendlyName.trim())) {
      setFieldNameRequire(true);
      return false;
    }
    return true;
  }

  function onApply() {
    const { detachFieldCallback, fieldMappingCallback, onCancelClicked, additionalInfo } = props;
    const { updateFieldState, nebUuid, isReadOnly, isNewFormField, isAnEcBrokenField } = additionalInfo;

    if((!isReadOnly && !isNewFormField && !isAdmin) || isReadOnly) {
      onCancelClicked();
      setIsApplyValid(false);
      return;
    } else if (fieldNameValidation()) {
      if (isAnEcBrokenField) {
        detachFieldCallback();
      }

      updateFieldState(nebUuid,fieldState);
      setIsApplyValid(false);
      fieldMappingCallback();
    }
  }

  function explicitFieldDetach() {
    const { detachFieldCallback, onCancelClicked } = props;
    detachFieldCallback();
    onCancelClicked();
  }

  function getCustomButtonsProps() {
    const { isReadOnly,isNewFormField } = props.additionalInfo;

    const customButton = {
      cancelButtonText:REUSEABLE_FIELD_MODAL.CANCEL,
      okButtonText: REUSEABLE_FIELD_MODAL.APPLY,
      containerStyle: { width: 500 },
    };
    if((!isReadOnly && !isNewFormField && !isAdmin) || isReadOnly) {
      customButton.cancelButtonText = '';
      customButton.okButtonText = REUSEABLE_FIELD_MODAL.CLOSE;
    }
    return customButton;
  }

  return (
    <ModalContainer>
      <ReportFieldMappingModal
        {...props}
        explicitFieldDetach={explicitFieldDetach}
        baseTextPath={BASE_TEXT_PATH}
        fieldState={fieldState}
        handleSetFieldState={handleSetFieldState}
        handleSetFieldObj={handleSetFieldObj}
        onOkClicked={onApply}
        setIsApplyValid={setIsApplyValid}
        isOkButtonDisabled={isApplyValid}
        fieldNameRequire={fieldNameRequire}
        additionalInfo = {{
          label: REUSEABLE_FIELD_MODAL.HELP,
          isLink: true,
          url: getSingleText(`${BASE_TEXT_PATH}helpLink`),
          iconClassName: 'neb-icon-help',
          ...props.additionalInfo,
        }}
        customButton = {getCustomButtonsProps()}
      />
    </ModalContainer>
  );

}

ReportFieldMappingModalContainer.propTypes = {
  additionalInfo: PropTypes.object,
  isShown:PropTypes.bool.isRequired,
  onCancelClicked:PropTypes.func,
  detachFieldCallback: PropTypes.func,
  fieldMappingCallback:PropTypes.func,
};

export default ReportFieldMappingModalContainer;
