//#region imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExpandableList from '@mdigital/components/dist/components/ExpandableList';
import { getSingleText } from '../../../../../ROOT/common/utils';
//#endregion imports


EcOptionsChanged.propTypes = {
  ecFields: PropTypes.object,
};

const StyledDetailsDivWrapper = styled.div`
  display: grid;
  grid-row-gap: 1em;
  margin-left: 1em;
`;

/**
 * About the business case:
 * - This has to do with `Reusable Fields`.
 * - When the user adds options to any form field this modal gets enabled.
 * - The options have to be added from the `MEC`.
 * - The scenario needs to use an integrated MEC property in Nebula.
 */
function EcOptionsChanged({ ecFields }) {
  const renderError = () => (
    <p>
      There was an error displaying the EC Fields that got added.<br />
      Please contact support.
    </p>
  );

  const renderEcOptionsChangedDetails = (labelAndFieldNames) => {
    // eslint-disable-next-line curly
    if (!labelAndFieldNames || !labelAndFieldNames.length) return renderError();

    const questionLabel = (label) => <p> <b> Question Label: </b> { label } </p>;
    const fieldDetails = (data) => {
      if (data.isIntegrationBroken) {
        return (
          <React.Fragment>
            <span>
              <span className="symbol required"/>
              <small><b> The following field is broken </b> (form has to be on draft or unpublished mode for fixing it) </small>
            </span>
            { questionLabel(data.label) }
          </React.Fragment>
        );
      }
      if (data.hasChangedOptions) {
        return (
          <React.Fragment>
            { questionLabel(data.label) }
            <p> <b>Reusable Field Name:</b> {data.reusableFieldName} </p>
          </React.Fragment>
        );
      }
    };

    return (
      <StyledDetailsDivWrapper>
        {labelAndFieldNames.map((data, index) => (
          <div key={index}>
            { fieldDetails(data) }
          </div>
        ))}
      </StyledDetailsDivWrapper>
    );
  };

  const renderEcOptionsChanged = () => {
    const pagesName = Object.keys(ecFields);
    // eslint-disable-next-line curly
    if (!pagesName || !pagesName.length) return renderError();

    return (
      <React.Fragment>
        <h4>Please review before saving</h4>
        <ExpandableList multipleExpanded={true}>
          {pagesName.map((pageName, index) => {
            const expandableListLabel = `Page "${pageName}"`;
            
            return (
              <ExpandableList.Item key={index} label={expandableListLabel}>
                {renderEcOptionsChangedDetails(ecFields[pageName])}
              </ExpandableList.Item>
            );
          })}
        </ExpandableList>
      </React.Fragment>
    );
  };

  const renderEcIntegrationBroken = () => (
    <h4> { getSingleText('app.pages.forms.builder.tabs.fieldSettings.tooltips.ecFieldDeleted') } </h4>
  );

  return (
    <section>
      { 
        ecFields
          ? renderEcOptionsChanged()
          : renderEcIntegrationBroken()
      }
    </section>
  );
}

export default EcOptionsChanged;