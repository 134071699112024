import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import BlockableRow from '../BlockableRow';
import { autoSuggestLabelByIdFinder } from '../../../../../../../common/utils';
import { ModalGlobalDataConsumer } from '../../../../../index';

const IS_ACTIVE_KEY = 'trigger.rules.MobileGenericRule.isActive';

const StyledElement = styled.div`
  width: 300px;
`;

const CustomRulesDropdown = ({ customRulesList = [], onChange, value }) => {
  const formattedRulesList = customRulesList.map((rule) => ({ id: rule.name, label: rule.id }));
  const appRatingRuleLabelFinder = formattedRulesList.length ? autoSuggestLabelByIdFinder(formattedRulesList) : (value) => `${value}`;

  return (
    <AutoSuggest 
      suggestions={formattedRulesList}
      placeholder="Select the rule"
      digDisableSuggest={true}
      onSuggestionSelected={({ label }) => onChange(label)}
      value={appRatingRuleLabelFinder(value)} />
  );
};

const customRuleDropdownTooltip = 'Rules must contain only custom parameters for this App rating. Rules are evaluated per SDK session, if not valid prompt will not be displayed';

const TargetByCustomRule = () => (
  <BlockableRow title="By Custom Rule" isActiveFieldName={IS_ACTIVE_KEY} tooltipText={customRuleDropdownTooltip}>
    <StyledElement>
      <Field name="trigger.rules.MobileGenericRule.params.ruleId" render={({ input }) => (
        <ModalGlobalDataConsumer>{({ customRules }) => (
          <CustomRulesDropdown {...input} customRulesList={customRules}  />
        )}</ModalGlobalDataConsumer>
      )} />
    </StyledElement>
  </BlockableRow>
);

export default TargetByCustomRule;