import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppRatingWizardModal from './AppRatingWizardModal';
import AppRatingCardCollection from './AppRatingCardCollection';
import AppRatingIntoductionRenderer from './AppRatingIntoductionRenderer';
import ActionBar from '../../components/ActionBar';
import LocalizationModal from '../../components/DigLocalizationModalContainer';
import { fetchAppRatings, createAppRating } from '../../../redux/actions/appRating.actions';
import { CARDS_ACTION_TYPES } from '../../../common/constants';
import { PROPERTY_TYPES } from '../../../common/constants';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getLocalStorageValue, getSingleText, pushNotification, setLocalStorageValue } from '../../../common/utils';
import { fetchAppRatingRules, fetchAppRatingRulesByAppRatingId } from '../../../redux/actions/appRatingRules.actions';
import { setLocalizationEngagementId } from '../../../redux/actions/localization.actions';
import { getInitialFormModalData, convertFormDataIntoContract, convertContractIntoFormData, convertAppRatingDataForCreate } from './helpers';
import {
  deleteAppRating,
  toggleAppRatingPublishedState,
  toggleAppRatingLockedState,
  getAppRating,
  updateAppRating,
  toggleAppRatingDarkModeState,
  fetchAppRatingDefualtBannerTheme
} from '../../../redux/actions/appRating.actions';
import withProvider from '../../hoc/ProviderHoc';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';

const INTRODUCTION_SHOWN_FLAG_KEY = 'MD__APP_RATING_INTRODUCTION_SHOWN_FLAG_KEY';
const MIGRATION_INTRODUCTION_SHOWN_FLAG_KEY = 'MD__APP_RATING_MIGRATION_INTRODUCTION_SHOWN_FLAG_KEY';
const SETTINGS_PATH_TO_DEFAULT_LANGUAGE = 'settings.localizationSettings.defaultLanguage';
const TRANSLATION_PATH_DELETE_MODAL = 'app.modal.deleteAppRating';

const ModalGlobalDataContext = React.createContext({});

export const ModalGlobalDataProvider = ModalGlobalDataContext.Provider;
export const ModalGlobalDataConsumer = ModalGlobalDataContext.Consumer;


@withProvider()
@connect(mapStateToProps, mapDispatchToProps)
class AppRatingPage extends React.Component {
  state = {
    wizardModalIsShown: false,
    modalInitialValues: null,
    selectRating: null,
    isShowDeleteModal: false,
    isEditing: false,
    showIntroduction: !getLocalStorageValue(INTRODUCTION_SHOWN_FLAG_KEY),
    showMigrationIntroduction: !getLocalStorageValue(MIGRATION_INTRODUCTION_SHOWN_FLAG_KEY),
  };

  initialFormModalData = getInitialFormModalData(this.props.propertyType);

  componentDidMount() {
    this.props.fetchAppRatings();
    this.props.fetchAppRatingDefualtBannerTheme();
  }

  componentDidUpdate(prevProps) {
    const { successMessage, errorMessage, onSuccessMessage, onErrorMessage } = this.props;
    successMessage && successMessage !== prevProps.successMessage && onSuccessMessage(successMessage);
    errorMessage && errorMessage !== prevProps.errorMessage && onErrorMessage(errorMessage);
  }

  closeModal = () => this.setState({ wizardModalIsShown: false, isEditing: false })

  openModal = (modalInitialValues, isEditing = false) => this.setState({
    wizardModalIsShown: true,
    isEditing,
    modalInitialValues,
  })

  handleSubmit = (data) => {
    const settings = getNestedProperty(SETTINGS_PATH_TO_DEFAULT_LANGUAGE) ? data.settings : {
      localizationSettings: {
        defaultLanguage: this.props.defaultLanguage,
        useCustomParam: false,
      },
    };
    const appRatingContract = { ...convertFormDataIntoContract(data), settings };

    const mobileAppVersion = getNestedProperty(data, 'trigger.rules.MobileAppVersion.params.versions');
    if(mobileAppVersion){
      data.trigger.rules.MobileAppVersion.params.versions = this.handleOsOrAppVersion(mobileAppVersion);
    }
    const MobileOsVersion = getNestedProperty(data, 'trigger.rules.MobileOsVersion.params.versions');
    if(MobileOsVersion){
      data.trigger.rules.MobileOsVersion.params.versions = this.handleOsOrAppVersion(MobileOsVersion);
    }
    if (this.state.isEditing) {
      this.props.updateAppRating(appRatingContract);
    } else {
      const createAppRatingContract = { ...convertAppRatingDataForCreate(data) };
      createAppRatingContract.appRating.settings = settings;
      this.props.createAppRating(createAppRatingContract);
    }
    this.closeModal();
  }

  handleOsOrAppVersion = (versions) =>versions && !Array.isArray(versions) ? versions.split(',') : versions

  handleClose = (formState) => {
    if (formState.pristine || confirm('Are you sure?')) {
      this.closeModal();
    }
  }

  handleCreateNewAppRating = () => {
    const mobileThemes = { themeData: this.props.theme.appRating.bannerThemeDefaultData };
    this.props.fetchAppRatingRules();
    if(this.props.isDarkModeEnabled) {
      this.initialFormModalData.isDarkModeEnabled = true;
    }
    this.initialFormModalData =  _.merge({}, this.initialFormModalData, { mobileThemes });
    this.openModal(this.initialFormModalData);
  }

  handleEditAppRating(appRating) {
    if (appRating.published && !confirm('You are about to edit a live App Rating, Are you sure you want to proceed?')) {
      return;
    }
    const { id } = appRating;
    const { getAppRating } = this.props;
    const promise = new Promise((resolve) => getAppRating(id, resolve));

    this.props.fetchAppRatingRulesByAppRatingId(id);
    promise.then(() => {
      const contractData = this.props.appRatings.find((item) => item.id === id);
      const convertedData = convertContractIntoFormData(contractData);
      const mergedInitialData = _.merge({}, this.initialFormModalData, convertedData);
      this.openModal(mergedInitialData, true);
    });
  }

  shouldModalShowDestinationPage = () =>
    this.props.propertyType === PROPERTY_TYPES.IOS || this.props.provisioning.mobileSDKV2AppRatingsAndroidDestination;

  handleTranslateClicked({ id }) {
    this.props.setLocalizationEngagementId(id);
  }

  handleAction = (actionType, data) => {
    const { id } = data;
    switch(actionType) {
      case CARDS_ACTION_TYPES.CREATE:
        this.handleCreateNewAppRating();
        break;
      case CARDS_ACTION_TYPES.PUBLISH:
        this.props.toggleAppRatingPublishedState(id, !data.published);
        break;
      case CARDS_ACTION_TYPES.EDIT:
        this.handleEditAppRating(data);
        break;
      case CARDS_ACTION_TYPES.LOCK:
        this.props.toggleAppRatingLockedState(id, !data.isLocked);
        break;
      case CARDS_ACTION_TYPES.DELETE:
        this.setState({ selectRating: data, isShowDeleteModal: true });
        break;
      case CARDS_ACTION_TYPES.TRANSLATE:
        this.handleTranslateClicked(data);
        break;
      case CARDS_ACTION_TYPES.DARK_MODE:
        this.props.toggleAppRatingDarkModeState(id, !data.isDarkModeEnabled);

    }
  }

  handleIntroductionClose = () => {
    this.setState({ showIntroduction: false });
    setLocalStorageValue(INTRODUCTION_SHOWN_FLAG_KEY, true);
  }

  handleMigrationIntroductionClose = () => {
    this.setState({ showMigrationIntroduction: false });
    setLocalStorageValue(MIGRATION_INTRODUCTION_SHOWN_FLAG_KEY, true);
  }


  handleNewIntroductionClose = () => {
    this.handleIntroductionClose();
    this.handleMigrationIntroductionClose();
  }

  onCloseDeleteConfirmationModal = () => {
    this.setState({ selectRating: null, isShowDeleteModal: false });
  }
  onDeleteConfirmed = () => {
    this.props.deleteAppRating(this.state.selectRating.id);
    this.onCloseDeleteConfirmationModal();
  }

  render() {
    if (!this.props.provisioning) {
      return null;
    }
    const directHelpLink = this.props.propertyType === PROPERTY_TYPES.IOS ?
      this.props.helpCenterLinks.appRatingIosDirect :
      this.props.helpCenterLinks.appRatingAndroidDirect;

    const isAndroidDirectProvisioned = this.props.provisioning['22dcr1_MPC_4800_mobileSDKV2AppRatingsAndroidDirect'] && this.props.propertyType === PROPERTY_TYPES.ANDROID;
    const isIOSDirectProvisioned = this.props.provisioning.mobileSDKV2AppRatingsiOSDirect && this.props.propertyType === PROPERTY_TYPES.IOS;

    return (
      <React.Fragment>
        <ActionBar
          title="App Rating"
          actionButtonText="New App Rating"
          shouldHideSearch={true}
          counter={this.props.appRatings.length}
          onAction={this.handleCreateNewAppRating} />
        <AppRatingCardCollection
          userRole={this.props.userRole}
          appRatings={this.props.appRatings}
          onAction={(actionType) => (data) => this.handleAction(actionType, data)}
          isDirectApiEnabled={isAndroidDirectProvisioned || isIOSDirectProvisioned}
          isDarkModeProvisionEnabled={this.props.isDarkModeEnabled}
        />
        <ModalGlobalDataProvider value={{
          propertyType: this.props.propertyType,
          provisions: this.props.provisioning,
          showScoreTargeting: this.props.provisioning.mobileSDKV2ScoreTargeting,
          showDestinationPage: this.shouldModalShowDestinationPage(),
          customRules: this.props.appRatingRules,
          helpLink: directHelpLink,
          isDarkModeEnabled: this.state.modalInitialValues ? this.state.modalInitialValues.isDarkModeEnabled : false,
          isNativeAlertRevamp: this.props.isNativeAlertRevamp,
        }}>
          <AppRatingWizardModal
            show={this.state.wizardModalIsShown}
            onSubmit={this.handleSubmit}
            onClose={this.handleClose}
            initialValues={this.state.modalInitialValues}
            helpCenterLinks={this.props.helpCenterLinks}/>
        </ModalGlobalDataProvider>
        <DeleteConfirmationModal
          isShown={this.state.isShowDeleteModal}
          title={getSingleText(`${TRANSLATION_PATH_DELETE_MODAL}.title`)}
          okButtonText={getSingleText(`${TRANSLATION_PATH_DELETE_MODAL}.delete`)}
          cancelButtonText={getSingleText(`${TRANSLATION_PATH_DELETE_MODAL}.cancel`)}
          getContent={() => getSingleText(`${TRANSLATION_PATH_DELETE_MODAL}.body`)}
          onOkClicked={this.onDeleteConfirmed}
          onCancelClicked={this.onCloseDeleteConfirmationModal}/>

        <AppRatingIntoductionRenderer
          showIntroduction={this.state.showIntroduction}
          showMigrationIntroduction={this.state.showMigrationIntroduction}
          mobileSDKV2AppRatingsiOSDirect={isIOSDirectProvisioned}
          mobileSDKV2AppRatingAndroidDirect={isAndroidDirectProvisioned}
          handleIntroductionClose={this.handleIntroductionClose}
          handleMigrationIntroductionClose={this.handleMigrationIntroductionClose}
          handleNewIntroductionClose={this.handleNewIntroductionClose} />
        <LocalizationModal engagementType={EngagementTypes.AppRating} addAlert={onLocalizationsSaved} />
      </React.Fragment>
    );
  }
}
function onLocalizationsSaved( type, message ) {
  pushNotification({ type, message: message });
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAppRatings: () => dispatch(fetchAppRatings()),
    fetchAppRatingRulesByAppRatingId: (appRatingId) => dispatch(fetchAppRatingRulesByAppRatingId(appRatingId)),
    fetchAppRatingRules: () => dispatch(fetchAppRatingRules()),
    toggleAppRatingPublishedState: (id, newState) => dispatch(toggleAppRatingPublishedState({ id, newState })),
    toggleAppRatingLockedState: (id, newState) => dispatch(toggleAppRatingLockedState({ id, newState })),
    deleteAppRating: (id) => dispatch(deleteAppRating(id)),
    createAppRating: (data) => dispatch(createAppRating(data)),
    getAppRating: (id, done) => dispatch(getAppRating(id, done)),
    updateAppRating: (data) => dispatch(updateAppRating(data)),
    setLocalizationEngagementId: (id) => dispatch(setLocalizationEngagementId(id)),
    toggleAppRatingDarkModeState: (id, newState) => dispatch(toggleAppRatingDarkModeState({ id, newState })),
    fetchAppRatingDefualtBannerTheme: () => dispatch(fetchAppRatingDefualtBannerTheme()),
  };
}

function mapStateToProps(state) {
  const { app, appRatings, profile, appRatingRules, theme } = state;
  const { propertyType, userRole, provisioning, defaultLanguage } = profile;
  const { successMessage, errorMessage } = app;
  return { appRatings, propertyType, provisioning, userRole, defaultLanguage, appRatingRules, successMessage, errorMessage, theme };
}

AppRatingPage.propTypes = {
  appRatingRules: PropTypes.array,
  appRatings: PropTypes.array,
  createAppRating: PropTypes.func,
  defaultLanguage: PropTypes.string,
  deleteAppRating: PropTypes.func,
  errorMessage: PropTypes.string,
  fetchAppRatingRules:PropTypes.func,
  fetchAppRatingRulesByAppRatingId: PropTypes.func,
  fetchAppRatings: PropTypes.func,
  getAppRating: PropTypes.func,
  helpCenterLinks: PropTypes.object,
  onErrorMessage: PropTypes.func,
  onSuccessMessage: PropTypes.func,
  propertyType: PropTypes.string,
  provisioning: PropTypes.array,
  setLocalizationEngagementId: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  store: PropTypes.any,
  successMessage: PropTypes.string,
  toggleAppRatingLockedState: PropTypes.func,
  toggleAppRatingPublishedState: PropTypes.func,
  updateAppRating: PropTypes.func,
  userRole: PropTypes.number,
  toggleAppRatingDarkModeState: PropTypes.func,
  isNativeAlertRevamp: PropTypes.bool,
  fetchAppRatingDefualtBannerTheme: PropTypes.func,
};

export default AppRatingPage;
