import React,{ Component } from 'react';
import DigTable from '@mdigital/components/dist/components/Table';
import { connect } from 'react-redux';
import { getSingleText } from '../../../common/utils';
import withProvider from '../../hoc/ProviderHoc';
import { bind } from '@mdigital/components/dist/decorators';
import PropTypes from 'prop-types';

function mapStateToModalProps(state){
  return {
    // the state defined by the reducers
    customParameters : state.customParameters,
    customParametersActions: state.customParametersActions,
    provisions: state.profile.provisioning,
    propertyType: state.property.propertyType,
    allEngagements: state.allEngagements,
  };
}

@withProvider()
@connect(mapStateToModalProps)
export default class DigCustomParametersContainer extends Component {

  constructor(props){
    super(props);
    this.tooltips = getSingleText('app.pages.administration.customParams.tooltips');
  }
  @bind
  checkProvision(provision) {
    const provisions = this.props.provisions;
    return !!provisions && (provisions[provision] === true || provisions[provision] === 'true');
  }

  originNameId = 'OriginNameId';
  @bind
  getCustomParamsColumns(){
      
    let columns = [
      { label: 'Parameter Name', tooltip: this.tooltips.parameterName },
      { label: 'Parameter Type', tooltip: this.tooltips.parameterType },
      { label: `${this.props.propertyType === PropertyTypes.WEBSITE ? 'Origin ' : ''}Type`,    tooltip: this.tooltips.originType },
      { id: this.originNameId, label: 'Origin Name',    tooltip: this.tooltips.originName, isHidden:this.shouldHideOriginName() },
      { label: 'Attach Forms',   tooltip: this.tooltips.attachForm }
    ];
    return columns;
  }
  @bind
  shouldHideOriginName() {
    return this.checkProvision(DigProvisions.MOBILE_SDKV2_TABLE_HIDE_CUSTOM_PARAMETER_ORG_NAME)
    && !(this.props.propertyType === PropertyTypes.WEBSITE)
    ;
  }

  isDisabledForDelete(customParam) {
    const isInUseInAnyForm = customParam.customParameterPerForms
      .reduce((accu, curr) => accu || curr.isCustomParamInUse, false);
    return customParam.isIdentifier || isInUseInAnyForm;
  }
  @bind
  formatAttachedFormsString(draftsCount, publishedCount) {
    return `${publishedCount > 0 ? (publishedCount + ' Published') : ''}` +
      `${publishedCount > 0 && draftsCount > 0 ? ', ' : ''}` +
      `${draftsCount > 0 ? draftsCount + ` Draft${draftsCount > 1 ? 's' : ''}` : ''} checked`;
  }
  @bind
  formatRows() {
    let customParameters;
    const isSurveyDraftEnabled = this.checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS);
    if (isSurveyDraftEnabled) {
      customParameters = this.props.customParameters;
    } else {
      customParameters = this.props.customParameters.map((cp) => {
        cp.customParameterPerForms = cp.customParameterPerForms.filter((cpPerForm) => cpPerForm.id !== CustomParamsActionsIds.ALL_PUBLISHED_FORMS);
        return cp;
      });
    }
    return customParameters
      .map((customParam) => {
        const attachForm = () => {
          const allAttachedFormsLength = customParam.customParameterPerForms.length;
          if (allAttachedFormsLength === 0) {
            return 'None';
          }
          switch (customParam.customParameterPerForms[0].id) {
            case -1: return 'All Forms';
            case -2: return 'All App Ratings';
            case -3: return 'All Forms & App Ratings';
          }
          if (!isSurveyDraftEnabled) {
            return `${allAttachedFormsLength} checked`;
          } else {
            const isAllPublishedForms = !!customParam.customParameterPerForms.find((form) => form.id === CustomParamsActionsIds.ALL_PUBLISHED_FORMS);
            let attachedPublishedCount = 0;
            let attachedDraftsCount = 0;
            customParam.customParameterPerForms.forEach((form) => this.props.allEngagements.find((engagement) => engagement === form.id) ? attachedPublishedCount++ : attachedDraftsCount++);
            return isAllPublishedForms ?
              this.formatAttachedFormsString(attachedDraftsCount - 1, this.props.allEngagements.length) :
              this.formatAttachedFormsString(attachedDraftsCount, attachedPublishedCount);
          }
        };


        let rowValues = [{ value: customParam.name }, { value: customParam.type }, { value: customParam.source }, {
          value: customParam.source_name,
          columnId: this.originNameId,
        }, { value: attachForm() }];

        return {
          id: customParam.id,
          isLocked: !!customParam.isIdentifier,
          lockTooltip : this.tooltips.disableCustomParamTooltip,
          values: rowValues,
          rowObject: customParam,
          actions: [
            {
              icon: 'pencil',
              callback: this.props.customParametersActions.onEdit,
              autLabel: 'dig-row-edit',
              isShowOnLock: false,
            },
            {
              icon: 'trash',
              callback: this.props.customParametersActions.onDelete,
              isDisabled: this.isDisabledForDelete(customParam),
              tooltip: this.isDisabledForDelete(customParam) ? this.tooltips.disableCpDeletionTooltip : '',
              autLabel: 'dig-row-delete',
              isShowOnLock: false,
            }
          ],
        };
      });
  }

  render() {
    return (
      <DigTable
        digColumns={this.getCustomParamsColumns()}
        digRows={this.formatRows()} />
    );
  }
}


DigCustomParametersContainer.propTypes = {
  store: PropTypes.object,
};

